import React, { FC } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Col, Row } from 'react-bootstrap';
import { RepresentativeCardProps } from 'components/representative/representaive-card.props';
import './representative-card.style.scss';
import Button from 'ui-kit/button/button';

const RepresentativeCard: FC<RepresentativeCardProps> = ({ name, phoneNumber, openDeleteModal, index }) => {
    const { t } = useTranslation();

    return (
        <div style={{ marginTop: '20px' }} className="border rounded rounded-2 border-sea-foam py-2">
            <Row>
                <Col xs={8} sm={10} md={10} lg={10}>
                    <Row className={`ml-4 d-flex`}>
                        <p className={'my-auto'}>{name}</p>
                    </Row>
                    <Row className={`ml-4 d-flex`}>
                        <p className={'my-auto'}>{phoneNumber}</p>
                    </Row>
                </Col>
                <Col xs={4} sm={2} md={2} lg={2} className={`d-flex justify-content-end my-auto`}>
                    <Button
                        className="btn-trash no-min-width p-0 mr-4"
                        variant="text"
                        label={t(`pages.profile.representatives.remove`)}
                        onClick={() => openDeleteModal(index)}
                        type="button"
                        trashIcon
                    />
                </Col>
            </Row>
        </div>
    );
};

export default RepresentativeCard;
