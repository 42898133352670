import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { LoadingMessageProps } from './loading-message.props';
import './loading-message.style.scss';

const LoadingMessage = ({ isVisible, text, className }: LoadingMessageProps) => {
    const { t } = useTranslation();
    const classes = classNames('loading-message', className);

    if (!isVisible) return null;

    // Set a default loading message if one is not passed in.
    if (text === undefined) {
        text = t('components.loadingMessage.defaultMessage');
    }

    return <div className={classes}>{text && <div className="loading-message-text">{text}</div>}</div>;
};

export default LoadingMessage;
